import { BET_TYPES as ALL_BET_TYPES } from 'utils/constants';

export const TABS = {
  SPORT: 'sport',
  TURFSPORT: 'turfsport',
};

// 10 seconds
export const REFETCH_INTERVAL = 10000;

export const BET_FILTER_PARAM_TYPES = {
  REJECT_REASON: 'rejectReason',
  PLACEMENT_PERIOD: 'placementPeriod',
  SETTLEMENT_PERIOD: 'settlementPeriod',
  TYPE: 'type',
  STATUS: 'status',
  SPORT_URN_ID: 'sportUrnId',
  SPORT_ID: 'sportId',
  CATEGORY_ID: 'categoryId',
  TOURNAMENT_URN_ID: 'tournamentUrnId',
  TOURNAMENT_ID: 'tournamentId',
  CATEGORY_URN_ID: 'categoryUrnId',
  EVENT_URN_ID: 'eventUrnId',
  RESULT: 'result',
  BET_SOURCE: 'betSource',
  IS_AUTO_UPDATE_ENABLED: 'isAutoUpdateEnabled',
  IS_ONLY_VIP: 'isOnlyVip',
  IS_MANUALLY_PROCESSED: 'isManuallyProcessed',
  TERM: 'term',
  SORT_BY: 'sortBy',
  BONUS_TYPE: 'bonusType',
  STAKE_EUR: 'stakeEur',
  STAKE_EUR_FROM: 'stakeEurFrom',
  STAKE_EUR_TO: 'stakeEurTo',
  WIN_EUR: 'winEur',
  WIN_EUR_FROM: 'winEurFrom',
  WIN_EUR_TO: 'winEurTo',
  VALUEDATOR_RESULT: 'valuedatorResult',
  VALUEDATOR_VALUE: 'valuedatorValue',
  VALUEDATOR_VALUE_FROM: 'valuedatorValueFrom',
  VALUEDATOR_VALUE_TO: 'valuedatorValueTo',
};

export const BET_SOURCE_TYPE = {
  DESKTOP: 'desktop',
  MOBILE_VERSION: 'mobile_version',
  MOBILE_APP: 'mobile_app',
  TABLET: 'tablet',
  UNKNOWN: 'unknown',
};

export const BET_RESULTS = {
  NO_RESULTS: 'no_results',
  WIN: 'win',
  LOSE: 'lose',
  REFUND: 'refund',
  CANCEL: 'cancel',
};

export const VALUEDATOR_RESULT = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  NO_DATA: 'no_data',
  DISABLED: 'disabled',
  ERROR: 'error',
};

export const BET_REJECT_REASONS = {
  VALUEDATOR: 'G01',
  FIELD_VALIDATION: 'G02',
  TICKET_VALIDATION: 'G03',
  DATA_FORMAT_VALIDATION: 'G04',
  SPORTS_DATA_ERROR: 'G05',
  LIMIT_ERROR: 'G06',
  LIABILITY_ERROR: 'G07',
  PLATFORM_ERRORS: 'G08',
};

export const BET_TYPES = Object.values(ALL_BET_TYPES).filter(
  type => type !== ALL_BET_TYPES.ALL && type !== ALL_BET_TYPES.CUSTOM_BET
);
